<template>
	<div class="home">
		<Homeindex></Homeindex>
	</div>
</template>

<script>
	import Homeindex from "../components/Home/HomeIndex.vue"
	export default {
		name: 'Home',
		components: {
			Homeindex
		}
	}
</script>
<style>

</style>
