<template>
	<div id="ntrance">
		<meta name="viewport"
			content="width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0,user-scalable=no" />
		<div class="showIndex">
			<div class="banner">
				<!-- <img   :src="imgUrl+img" alt="" /> -->
				<van-swipe :autoplay="3000" lazy-render>
					<van-swipe-item v-for="(item, index) in img">
						<img :src="imgUrl + item" />
					</van-swipe-item>
				</van-swipe>
			</div>
			<!-- <div>{{name}}</div> -->
			<div style="overflow: auto;height: 65vh;">
				<div v-for="(item, index) in arr">
					<div class="cenBetween">
						<div class="betLeft">
							<div style="margin-top: 0.125rem;"><img src="../../image/zuan.png" /></div>
							<div class="betNew">{{ item.equity_type_name }}</div>
							<div class="leBcg">{{ item.equity_type_desc }}</div>
						</div>
						<!-- 	<div class="betRight" @click="btnDetail(item.id)">
					<div style="font-size: 0.75rem;margin-right: 0.3125rem;margin-top:0.125rem;color: #666666;">更多</div>
					<div class="rigImg"><img src="../../image/left.png"/></div>
				</div> -->

					</div>
					<div>
						<div class="content" v-for="(item1, index1) in item.equityData" @click="btnCon(item1.id)">
							<div class="conLeft">
								<img :src="imgUrl + item1.logo" />
							</div>
							<div style="margin-left: 0.3125rem;width: 70%;">
								<div class="conRig">
									<div style="font-size: 0.75rem;font-weight: bold;margin-top: 0.125rem;">
										{{ item1.equity_name | ellipsis }}</div>
									<div style="font-size: 0.75rem;margin-left: 1.875rem;color: #666666;">></div>
								</div>
								<div style="display: flex;margin-top:1.25rem;">
									<div><img class="imgAddress" src="../../image/address.png" /></div>
									<div style="font-size: 0.625rem;margin-top: 0.2rem;margin-left: 0.125rem;">
										{{ item1.address | ellipsi }}</div>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>


		</div>
		<div class="bot">
			<img src="../../image/btnok.png" alt="" @click="btnIndex()" />
		</div>
	</div>
</template>
<script>
import {
	Dialog
} from 'vant';
export default {

	name: "Entrance",

	data() {
		return {
			orderDetail: '',
			imgUrl: '',
			name: '',
			img: [],
			arr: [



			],

		};
	},
	components: {},
	filters: {

		ellipsi(value) {
			if (!value) return ''
			if (value.length > 16) {
				return value.slice(0, 16) + '...'
			}
			return value
		},
		ellipsis(value) {
			if (!value) return ''
			if (value.length > 12) {
				return value.slice(0, 12) + '...'
			}
			return value
		}
	},
	methods: {
		btnDetail(e) {
			console.log(e)
			// return false
			this.$router.push({
				path: "/detail?id=" + e,
			})
		},
		btnIndex() {
			this.$router.push({
				path: "/show",
			})
		},
		btnCon(e) {

			this.$router.push({
				path: "/introduce?id=" + e,
			})
		},
	},
	created() {
		var imgUrl = this.$request.imgUrl()
		this.imgUrl = imgUrl
		this.$storage.setLocal(this.$route.query.store_id, 'store_id');
		// this.$storage.setLocal(this.$route.query.stock_id, 'stock_id');
		this.$request.getList({
			store_id: this.$storage.getLocal('store_id'),
		}).then(res => {
			this.arr = res.data

		})
		this.$request.getBanner({
			store_id: this.$storage.getLocal('store_id'),
		}).then(res => {
			var imgStr = res.data
			var imgArr = imgStr.split(',')
			this.img = imgArr
			// console.log(imgArr)
		})
		// this.$storage.setLocal("oT0t5xHubyKkpzNORcE05bhauZns", 'openId')
		// this.$storage.setLocal(1, 'openId')
		// return false




		// debugger;
		// 从 localStorage 中获取 openid 判断该用户是否已经通过验证
		let openid = sessionStorage.getItem("openid");
		// 跳转到该页之前的路径
		let search_url = window.location.search;
		// 如果 openid 为空 说明是第一次打开登录页
		if (!openid) {
			// 微信公众号 appid ，无需修改
			let appid = "wxfb3e3d5a2895f998";
			// 微信公众号 secret ，无需修改
			let secret = "ea76999d2fa43d61ef2ba43ce53ae5d6";
			// 回调地址，无需修改1bcd07000e8bed073b67f298a0c95e67
			//let index = "http://127.0.0.1:8080/cloud_parks/#/Jump";
			let index = "https://activeh5.yinheyun.com.cn/#/?store_id=" + this.$storage.getLocal('store_id');
			// 获取 openid 地址，127.0.0.1:8080 为开发使用，上线后可根据服务实际 ip 和 端口 进行调整
			let access_token = ""
			"http://192.168.253.24:8080/weique/cloud/ycy/ycyService/weChat/test";    //测试地址
			// "http://47.102.147.120:8080/weique/cloud/ycy/ycyService/weChat/test";  //打包显示

			// 告诉微信 我要获取 code
			if (!window.location.search) {
				let authorize_rul =
					`https://open.weixin.qq.com/connect/oauth2/authorize?appid=` +
					appid +
					`&redirect_uri=${encodeURIComponent(index)}` +
					`&response_type=code` +
					`&scope=snsapi_base` +
					`&state=STATE#wechat_redirect`;
				window.location.href = authorize_rul;
			} else {
				console.log(555555);
				let params = window.location.search.match(/^[?,#]code=(\S*)&state=(\S*)/);
				if (params) {
					let [match, code, state] = params; // 解构正则匹配出的参数
					this.name = code
					console.log(code)
					this.$request.getOpenId({
						code: code
					}).then(res => { //已选商品
						console.log('openid',res)
						this.$storage.setLocal(res.data.openid, 'openId')

						// window.location.href =
						// "https://activeh5.yinheyun.com.cn/#/store_id="+this.$storage.getLocal('store_id')
					})
					// this.$axios({
					// method: "post",
					// url: access_token + "?code=" + code
					// }).then(response => {
					// 说明 第一次获取到 openid，并写入localStorage
					// sessionStorage.setItem("openid", response.data.entity);
					// 跳转到首页
					// let  nourl= sessionStorage.getItem('noUrl');
					// if(nourl){
					// window.location.href =
					// "http://kc.cross.echosite.cn/cloud_parks/#/"+nourl;
					// }else{
					// window.location.href =
					// "http://kc.cross.echosite.cn/cloud_parks/#/index";
					// }
					// });
				} else {
					// 添加参数 verification=ok 的原因是，验证通过后跳转到原页面，要告诉原页面已经是通过验证得了，如果没有该参数判定会导致死循环
					window.location.href = window.location.search + "&verification=ok";
				}
			}
		} else {
			this.$router.push({
				name: "index"
			})
		}
	},
	beforeCreate() { },
	mounted() { },
	destroyed() { },
};
</script>

<style scoped>
#ntrance {
	width: 100vw;
	height: 100vh;
	background: #f3f3f3;
	padding-top: 0.5rem;

}

.imgAddress {
	width: 0.5rem;
	height: 0.6875rem;
}

.bot {
	width: 100%;
	height: 3.125rem;
	position: fixed;
	bottom: 0;
	background-color: white;
	/* text-align: center; */
	align-items: center;
	display: flex;
}

.bot img {
	width: 94%;
	margin-left: 3%;
	height: 90%;

}

.showIndex {
	width: 94%;
	margin-left: 3%;
	/* border: 1px solid black; */
}

.banner {
	border-radius: 10px;
	/* border: 1px solid black; */
}

.banner img {
	width: 100%;
	height: 6.8125rem;
	border-radius: 10px;
}

.cenBetween {
	display: flex;
	justify-content: space-between;
	margin-top: 0.3125rem;
	padding-bottom: -1.25rem;
}

.betLeft {
	display: flex;

}

.betRight {
	display: flex;
}

.leBcg {
	display: flex;
	padding-left: 0.3125rem;
	margin-left: 0.125rem;
	padding-right: 0.3125rem;
	color: white;
	border-radius: 1.25rem;
	margin-top: 0.125rem;
	align-items: center;
	height: 70%;
	font-size: 0.625rem;
	background-color: #FF524F;
}

.rigImg {
	margin-top: 0.1575rem;
}

.rigImg img {
	width: 0.8125rem;

}

.content {
	display: flex;
	margin-top: 0.3125rem;
	align-items: center;
	width: 100%;
	height: 5rem;
	background-color: white;
	border-radius: 10px;
}

.conLeft {
	/* display: flex; */
	width: 23%;
	/* width: 4rem; */
	height: 4rem;
	margin-left: 0.5rem;

}

.conLeft img {
	width: 100%;
	height: 100%;
}

.conRig {
	/* border: 1px solid black; */
	/* width: 120%; */

	display: flex;
	justify-content: space-between;
}

.betNew {
	font-size: 1rem;
	font-weight: bold;
	margin-left: 0.125rem;

}
</style>
